import React, { useEffect, useState } from 'react'; // Ensure to import useEffect and useState
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import { BASE_URL_IMAGE, END_POINT } from '../../Helper/helper';
import { fetchData } from "../../Helper/ApiCall";

const Testimonials = () => {
    const options = {
        loop: true,
        margin: 10,
        nav: false,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 2,
            },
        },
    };

    const [TesimonialData, setTestimonial] = useState([]);

    useEffect(() => {
        TestimonialHandler();
    }, []);

    const TestimonialHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetNewWeddingTestimonial);

            if (result.success === true) {
                setTestimonial(result.data); // Update the state with the fetched data
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div className="main_section testimonial">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="about-text">
                                <h2>Testimonials</h2>
                                <p>
                                    These real couples said their “I do” in a glamorous way. <br />
                                    Let’s walk you through their fairytale wedding.
                                </p>
                            </div>
                        </div>
                        <br />
                        <br />
                        {TesimonialData.length === 0 ? (
                            <div className="loading-message">Loading testimonials...</div>
                        ) : (
                            <OwlCarousel options={options}>
                                {TesimonialData.map((testimonial, index) => (
                                    <div key={index}>
                                        <div className="item owl-item">
                                            <div className="textimonial-box">
                                                <div className="left-textimonial">
                                                    <img
                                                        className='img-fluid'
                                                        src={testimonial.image ? BASE_URL_IMAGE + testimonial.image : '/path/to/default/image.jpg'}
                                                        alt={`Testimonial from ${testimonial.name || 'Client'}`}
                                                        style={{ width: '100%', height: 'auto' }}
                                                    />
                                                </div>
                                                <div className="right-textimonial">
                                                    <p>{testimonial.title || "Thank you for your services!"}</p>
                                                    <span>{testimonial.name || "Client Name"}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        )}
                    </div>
                </div>
            </div>
        </>
    );

};

export default Testimonials;
