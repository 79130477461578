import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';

const Testimonials = () => {
    const options = {
        loop: true,
        margin: 10,
        nav: false,  // Disable navigation arrows
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 2,
            },
        },
    };


    // const options = {
    //     loop: true,
    //     margin: 10,
    //     nav: true,  // Enable navigation arrows
    //     navText: ["<", ">"],  // Custom navigation text
    //     responsive: {
    //         0: {
    //             items: 1,
    //         },
    //         600: {
    //             items: 1,
    //         },
    //         1000: {
    //             items: 4,
    //         },
    //     },
    // };

    const images = [
        '/assets/images/Wedding planners.png',
        '/assets/images/Wedding planners.png',
        '/assets/images/Wedding planners.png',
        '/assets/images/Wedding planners.png',
        '/assets/images/Wedding planners.png',
        '/assets/images/Wedding planners.png',

    ];

    return (

        <>
            <div className="main_section testimonial">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="about-text">
                                <h2>Testimonials</h2>
                                <p>
                                    These real couples said their “I do” in a glamorous way. <br />
                                    Let’s walk you through their fairytale wedding.
                                </p>
                            </div>
                        </div>
                        <br />
                        <br />
                        <OwlCarousel options={options}>
                            {images.map((image, index) => (
                                <div key={index}>

                                    <div className="owl-item">
                                        <div className="item owl-item cloned" style={{
                                            width: '90%', marginRight: '50px'
                                        }}>
                                            <div className="textimonial-box">
                                                < div className="left-textimonial">
                                                    <img className='img-fluid' src={image} alt={`Slide ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
                                                </div>
                                                <div className="right-textimonial">
                                                    <p>THANK YOU for making our WEDDING week so FABULOUS! Everything from the small details to the months and months of planning were ABSOLUTELY PERFECT and we couldn't have done it all without the tea,</p>
                                                    <span>Client name</span>
                                                </div>



                                            </div>
                                        </div></div>

                                </div>
                            ))}
                        </OwlCarousel>
                    </div >
                </div >
            </div >

        </>
    );
};

export default Testimonials;
