import React, { useRef, useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import { Container } from "react-bootstrap";
import { fetchData } from "../Helper/ApiCall";
import { BASE_URL_IMAGE, END_POINT } from "../Helper/helper";

function PartnersCarousel2() {
    const [joinnetwork, setjoinnetworkData] = useState([]);

    useEffect(() => {
        Handler();
    }, []);

    const Handler = async () => {
        try {
            const result = await fetchData(END_POINT.GetDataJoinNetwork);
            if (result.success == true) {
                setjoinnetworkData(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <br></br>
            <br></br>
            <div className="container partnership mx-auto">
                <Container>
                    <Swiper
                        slidesPerView={6}
                        spaceBetween={5}
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 1500,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        breakpoints={{
                            "@0.00": {
                                slidesPerView: 2,
                                spaceBetween: 5,
                            },
                            "@0.75": {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },
                            "@1.00": {
                                slidesPerView: 4,
                                spaceBetween: 10,
                            },
                            "@1.50": {
                                slidesPerView: 5,
                                spaceBetween: 10,
                            },
                        }}

                        className="mySwiper"
                    >
                        {joinnetwork.length > 0 &&
                            joinnetwork.map((item) => {
                                // console.log(item);
                                return (
                                    <SwiperSlide>
                                        {/* <div class="partner-img-overlay"></div> */}
                                        <div className="slides">
                                            <div className="partner-carousel-img-cont">
                                                <img
                                                    // src="/assets/img/sliderimg.png"
                                                    src={BASE_URL_IMAGE + item.image}
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                );
                            })}

                    </Swiper>
                </Container>
            </div>
            <br></br>
            <br></br>

        </>
    );
}

export default PartnersCarousel2;
