import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperNavButtons } from "../../components/SwiperNavButton";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { SwiperNavYellow } from "../../components/SwiperNavYellow";
import {
    END_POINT,
    BASE_URL_IMAGE,
    METHODS,
    NumberSeprator,
    numberToEnglish,
    TabBarLabels,
    BASE_URL,
} from "../../Helper/helper";

import "../../../src/weddingstyle.css";

import EditorRead from "../../components/EditorRead";
import Testimonial from "../../components/Testimonial";
import PartnersCarousel from "../../components/PartnersCarousel";
import Subscribe from "../../components/Subscribe";
import { useSelector, useDispatch } from "react-redux";
import { homedataUpdate } from "../../redux/reducers/homeReducer";
import ActivityLoader from "../../components/ActivityLoader";
import { fetchData } from "../../Helper/ApiCall";
import Testimonials from "../Wedding/Testimonials";
import PartnersCarousel2 from "../../components/PartnersCrousal2";
import MiceTestimonials from "./MiceTestimonial";

export const NewMiceScreen = () => {
    const dispatch = useDispatch();

    const { HomeBanner, SectionSecond, RecomendedBlog, SectionForth, loading } =
        useSelector((store) => store.homeReducer);

    const navigate = useNavigate();
    const [data, setdata] = useState({
        WeddingDetail: [],
        Wedding: [],
        WeddingBanner: [],
        WeddingReal: [],
        WeddingInspiration: [],
    });
    const [TestimonialData, setTestimonialData] = useState([]);

    useEffect(() => {
        handlerData();
        if (SectionForth == "") {
            homeHandler();
        }
        // handler();
        // handlerInspiration();
    }, []);
    const handlerData = async () => {
        try {
            const result = await fetchData(END_POINT.GetWeddingPageData);
            if (result.success == true) {
                setdata({
                    WeddingDetail: result.data.WeddingDetail,
                    Wedding: result.data.Wedding,
                    WeddingBanner: result.data.WeddingBanner,
                    WeddingReal: result.data.WeddingReal,
                    WeddingInspiration: result.data.WeddingInspiration,
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const homeHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetWebHomeAllData);
            console.log(result);
            if (result.success == true) {
                dispatch(homedataUpdate(result.data));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handler = async () => {
        try {
            const result = await fetchData(
                END_POINT.GetDetailByPageDataTestimonial + "Wedding"
            );
            if (result.success == true) {
                setTestimonialData(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add functionality to handle the email submission
        console.log(`Email submitted: ${email}`);
    };

    const options = {
        loop: true,
        margin: 10,
        nav: true,
        dots: true,
        // navText: ['<', '>'],
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 4,
            },
        },
    };

    return (
        <>

            {/* Video Banner Section */}
            <div className="main_section banner">
                <video autoPlay muted loop playsInline preload="metadata">
                    <source src="/assets/images/weeding.mp4" type="video/mp4" />
                </video>
            </div>

            {/* About Section */}
            <div className="main_section about"
                style={{ backgroundImage: "url('/assets/images/our-concept.jpg')" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="about-text">
                                <h2>Destination Siam M.I.C.E </h2>
                                <div className="article">
                                    <p>
                                        Established in 2006, Destination Siam Group started as a local tour operator in Thailand targeting Indian tourists. Today, we are the leading DMC that provides its services to more than 100,000 tourists from 30+countries.
                                    </p>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="main_section weeding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="about-text weeding-text">
                                <h2>M.I.C.E Inspiration</h2>
                                <p>
                                    Description for MICE venues
                                </p>
                            </div>
                        </div>

                        <div className="col-md-12 p-0 mt-4 desktop-view">
                            <div className="weeding-box">
                                <div className="left-weeding-box">
                                    <div className="place-box-new">
                                        <div className="cus-place-new-img">
                                            <img
                                                src="/assets/images/Best Fine dining mice.jpg"
                                                className="img-fluid"
                                                alt="Best Fine Dining"
                                            />
                                        </div>
                                        <div className="cus-place-text-new">
                                            <h3>M.I.C.E Expert Services</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="right-weeding-box">

                                    <div className="right-bottom">
                                        <div className="right-one">
                                            <div className="place-box-new place-box-new-mice">
                                                <div className="cus-place-new-img">
                                                    <img
                                                        src="/assets/images/Must do activities mice.png"
                                                        className="img-fluid"
                                                        alt="Preferred Hotels"
                                                    />
                                                </div>
                                                <div className="cus-place-text-new">
                                                    <h3>M.I.C.E trends in 2024</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="right-two">
                                            <div className="place-box-new place-box-new-mice">
                                                <div className="cus-place-new-img">
                                                    <img
                                                        src="/assets/images/Preferred Hotels.jpg"
                                                        className="img-fluid"
                                                        alt="Best Fine Dining 2"
                                                    />
                                                </div>
                                                <div className="cus-place-text-new">
                                                    <h3>Cement group to Phuket</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="right-bottom">
                                        <div className="right-one">
                                            <div className="place-box-new place-box-new-mice">
                                                <div className="cus-place-new-img">
                                                    <img
                                                        src="/assets/images/Preferred Hotels1.jpg"
                                                        className="img-fluid"
                                                        alt="Preferred Hotels"
                                                    />
                                                </div>
                                                <div className="cus-place-text-new">
                                                    <h3>Vestiges in Pattaya</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="right-two">
                                            <div className="place-box-new place-box-new-mice">
                                                <div className="cus-place-new-img">
                                                    <img
                                                        src="/assets/images/Best-Fine-dining-2.jpg"
                                                        className="img-fluid"
                                                        alt="Best Fine Dining 2"
                                                    />
                                                </div>
                                                <div className="cus-place-text-new">
                                                    <h3>PNB Pan India to Bangkok</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Mobile View - Carousel */}
                        <div className="col-md-12 p-0 mt-4 mobile-view">

                            <OwlCarousel options={options}>
                                <div className="item">
                                    <img src="/assets/images/Best Fine dining mice.jpg" className="img-fluid" alt="Best Fine Dining" />
                                </div>
                                <div className="item">
                                    <img src="/assets/images/Must do activities mice.png" className="img-fluid" alt="Must-do Activities" />
                                </div>
                                <div className="item">
                                    <img src="/assets/images/Preferred Hotels.jpg" className="img-fluid" alt="Preferred Hotels" />
                                </div>
                                <div className="item">
                                    <img src="/assets/images/Preferred Hotels1.jpg" className="img-fluid" alt="Best Fine Dining 2" />
                                </div>
                            </OwlCarousel>
                        </div>

                    </div>
                </div>
            </div>



            <div className="main_section real">
                <div className="real-img">
                    <img src="/assets/images/icon2.png" className="img-fluid" alt="Icon 2" />
                </div>
                <div className="main_section highlight">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="about-text destination-text" style={{ textAlign: 'center' }}>
                                    <h2>M.I.C.E Destinations</h2>
                                    <p>Discover our top destinations for hosting your next MICE events. Explore premier locations that offer the perfect <br></br> blend of convenience, luxury, and functionality, ensuring your event is both memorable and successful</p>
                                </div>

                                <div className="main-highlight-box">
                                    <OwlCarousel options={options}>

                                        <div className="item">
                                            <img src="/assets/images/Destination3.png" className="img-fluid" alt="Must-do Activities" />
                                        </div>
                                        <div className="item">
                                            <img src="/assets/images/Destination2.png" className="img-fluid" alt="Preferred Hotels" />
                                        </div>
                                        <div className="item">
                                            <img src="/assets/images/Destination1.png" className="img-fluid" alt="Best Fine Dining 2" />
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="real-img1">
                    <img src="/assets/images/icon1.png" className="img-fluid" alt="Icon 1" />
                </div>
            </div>


            {/* Wedding Venues Section */}
            <div className="main_section weeding venues">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="about-text weeding-text">
                                <h2>M.I.C.E Venues</h2>
                                <p>
                                    Description for MICE venues
                                </p>
                            </div>
                        </div>

                        <div className="col-md-12 p-0 wp-according mt-4">
                            <div className="accordion">
                                <div className="item bg-barcelona"
                                    style={{ backgroundImage: "url('/assets/images/Rectangle-1.jpg')" }}
                                >
                                    <div className="overlay">
                                        <div className="overlay-inner">
                                            <h3>
                                                <a href="#">Name of the hotel</a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="item bg-sydney"
                                    style={{ backgroundImage: "url('/assets/images/Rectangle-2.jpg')" }}
                                >
                                    <div className="overlay">
                                        <div className="overlay-inner">
                                            <h3>
                                                <a href="#">Name of the hotel</a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="item bg-venice"
                                    style={{ backgroundImage: "url('/assets/images/Rectangle-3.jpg')" }}
                                >
                                    <div className="overlay">
                                        <div className="overlay-inner">
                                            <h3>
                                                <a href="#">Name of the hotel</a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-md-12 p-0 wp-according mt-4 mobile-view">
                            <OwlCarousel options={options}>

                                <div className="item">
                                    <img src="/assets/images/Rectangle-1.jpg" className="img-fluid" alt="Must-do Activities" />
                                </div>
                                <div className="item">
                                    <img src="/assets/images/Rectangle-2.jpg" className="img-fluid" alt="Preferred Hotels" />
                                </div>
                                <div className="item">
                                    <img src="/assets/images/Rectangle-3.jpg" className="img-fluid" alt="Best Fine Dining 2" />
                                </div>
                            </OwlCarousel>
                        </div> */}

                    </div>
                </div>
            </div>

            <MiceTestimonials />


            {/* Call to Action Section */}
            <div className="main_section cta"
                style={{ backgroundImage: "url('/assets/images/CTA.png')" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="cta-text">
                                <h2>
                                    Let our expert MICE planners bring  <br />
                                    your event vision to life.
                                </h2>
                                <a href="#" className="cta-btn">Our service offerings</a>
                                <a href="#" className="cta-btn1">Send in inquiry</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Highlight Section */}
            {/* <div className="main_section highlight desktop-view">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about-text">
                                <h2>M.I.C.E Highlights!</h2>
                                <p>A series of highlights showcasing recent M.I.C.E events</p>
                            </div>
                            <div className="main-highlight-box">
                                <div className="highlight-box">
                                    <img src="/assets/images/Preferred-Hotels.jpg" className="img-fluid" alt="Preferred Hotels" />
                                </div>
                                <div className="highlight-box">
                                    <img src="/assets/images/Must-do-activities.jpg" className="img-fluid" alt="Must do activities" />
                                </div>
                                <div className="highlight-box">
                                    <img src="/assets/images/Preferred-Hotels.jpg" className="img-fluid" alt="Preferred Hotels" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="main_section highlight">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about-text">
                                <h2>M.I.C.E Highlights!</h2>
                                <p>A series of highlights showcasing recent M.I.C.E events</p>
                                <br />
                            </div>
                            <div className="main-highlight-box">
                                <div className="main-highlight-box">
                                    <OwlCarousel options={options}>

                                        <div className="item">
                                            <video autoPlay muted loop playsInline preload="metadata">
                                                <source src="/assets/images/Gold and Brown Minimalist Wedding Invitation Video.mp4" type="video/mp4" />
                                            </video>
                                        </div>
                                        <div className="item">
                                            <video autoPlay muted loop playsInline preload="metadata">
                                                <source src="/assets/images/Gold and Brown Minimalist Wedding Invitation Video.mp4" type="video/mp4" />
                                            </video>
                                        </div>
                                        <div className="item">
                                            <video autoPlay muted loop playsInline preload="metadata">
                                                <source src="/assets/images/Gold and Brown Minimalist Wedding Invitation Video.mp4" type="video/mp4" />
                                            </video>
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="main_section cta newsletter"
                style={{ backgroundImage: "url('/assets/images/CTA.png')" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="cta-text">
                                <h2>Sign up for our amazing deals & Newsletter</h2>
                                <div className="cus-newsletter">
                                    <form className="row g-3" onSubmit={handleSubmit}>
                                        <div className="col-md-8 col-7">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="inputEmail4"
                                                placeholder="Enter email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-4 col-5">
                                            <button type="submit" className="btn btn-primary">Subscribe</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PartnersCarousel2 />


        </>
    );
};
