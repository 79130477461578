export const PublicKeYOmise = "pkey_5w25u8jq792a6qhysh5";
// export const PublicKeYOmise = "pkey_test_5sqyh88gojoro8lm6vi";

export const GOOGLE_MAPS_APIKEY = "AIzaSyCIdnH9jJ_mfZXIRDJpTpt_yaCyvhB4aU0";
// export const GOOGLE_MAPS_APIKEY = "AIzaSyBPk5ELlNGieW_hRmtdNKCufNAC2Cg6e7o";
// export const GOOGLE_MAPS_APIKEY = "AIzaSyCIdnH9jJ_mfZXIRDJpTpt_yaCyvhB4aU0";
export const ExchangeApi =
  "https://v6.exchangerate-api.com/v6/aacf0355da89eff2488814a1/latest/THB";
// "https://v6.exchangerate-api.com/v6/544c8c5cc34451bd08bd8d9d/latest/THB";
export const CountryCheckApi = "https://ipapi.co/json/";
export const isLive = true;

export const BASE_URL_LOCAL = "http://localhost:5001";
// export const BASE_URL_LIVE = "http://149.28.240.209:5001";
// export const BASE_URL_LIVE = "https://139.180.213.140:5001";
export const BASE_URL_LIVE = "https://bharatchoice.world";
export const BASE_URL = isLive ? BASE_URL_LIVE : BASE_URL_LOCAL;
export const BASE_URL_IMAGE = BASE_URL + "/uploads/";

export const END_POINT = {
  PdfFileUpload: BASE_URL + "/api/file-upload",
  VideoUpload: BASE_URL + "/api/video-upload",
  ImageUpload: BASE_URL + "/api/picture-upload",
  MoreImageUpload: BASE_URL + "/api/multiple-picture-upload",
  HotelCreate: BASE_URL + "/api/hotel-create",
  UpdateHotel: BASE_URL + "/api/hotel-update/",
  GetHotelByid: BASE_URL + "/api/hotelbyid/",
  GetHotelByCity: BASE_URL + "/api/get-hotel-by-city",

  GetHotelCity: BASE_URL + "/api/hotelcitylist",
  GetHotelCityByid: BASE_URL + "/api/hotelcitybyid/",

  AddHotelCity: BASE_URL + "/api/hotelcity-create",
  UpdateHotelCity: BASE_URL + "/api/hotelcity-update/",
  GetHotelList: BASE_URL + "/api/hotellist",
  GetRoomFacility: BASE_URL + "/api/hotelroomfacilitylist",
  AddRoomFacility: BASE_URL + "/api/hotelroomfacility-create",

  UpdateRoomFacility: BASE_URL + "/api/hotelroomfacility-update/",
  GetHotelsRoomFacilityByid: BASE_URL + "/api/hotelroomfacilitybyid/",

  DeleteHotels: BASE_URL + "/api/hotel-delete/",
  DeleteHotelCity: BASE_URL + "/api/hotelcity-delete/",
  DeleteRoomFacility: BASE_URL + "/api/hotelroomfacility-delete/",

  AddRoomType: BASE_URL + "/api/add-room-type",
  UpdateRoomType: BASE_URL + "/api/update-room-type/",

  GetRoomType: BASE_URL + "/api/room-type-list",
  GetRoomTypeById: BASE_URL + "/api/roomTypeListById/",
  GetRoomTypeByRoomId: BASE_URL + "/api/roomTypeById/",
  DeleteRoomType: BASE_URL + "/api/delete-room-type/",

  PostHotelStock: BASE_URL + "/api/add-hotel-stock",
  PostHotelCampaign: BASE_URL + "/api/add-hotel-campaign",
  UpdateHotelStockStatus: BASE_URL + "/api/add-hotel-stock-status/",
  GetHotelStock: BASE_URL + "/api/get-hotel-stock",
  GetHotelStockByid: BASE_URL + "/api/get-hotel-stock-byid",
  GetHotelStockByHotelid: BASE_URL + "/api/gethotelstockbyhotelid/",
  GetMultipleHotelStock: BASE_URL + "/api/get-multiple-hotel-stock",

  HotelBooking: BASE_URL + "/api/booking/",
  GetHotelBooking: BASE_URL + "/api/all-bookings/",
  GetHotelBookingBtId: BASE_URL + "/api/get-booking-byid/",
  GetUpcomingTrips: BASE_URL + "/api/get-upcoming-booking/",
  GetPreviousTrips: BASE_URL + "/api/get-previous-booking/",

  //user apis
  LoginUser: BASE_URL + "/api/user/login",
  FacebookLogin: BASE_URL + "/api/facebook-login",
  GoogleLogin: BASE_URL + "/api/google-login",
  GetUser: BASE_URL + "/api/getuserbyid/",
  GetUsersByPagination: BASE_URL + "/api/user",
  GetUserBytype: BASE_URL + "/api/user-type/:type",
  GetAllUsers: BASE_URL + "/api/users",
  PostUser: BASE_URL + "/api/user-create",
  UpdateUser: BASE_URL + "/api/user-update/",
  UserSearch: BASE_URL + "/api/user-serach/:query",
  DeleteUsers: BASE_URL + "/api/user-delete/",

  //omise payment
  omiseCheckoutCreditCard: BASE_URL + "/api/checkout-creditCard",
  omiseCheckoutInternetBanking: BASE_URL + "/api/checkout-internetBanking",
  omisepaymentcheck: BASE_URL + "/api/check-payment-status",
  omiseWebHooks: BASE_URL + "/api/webhooks",
  getInternetBankingCharge: BASE_URL + "/api/bank-charge",

  //Change Password

  UserChangePassword: BASE_URL + "/api/user-change-password/",

  //Wedding Details

  AddWeddingDeatils: BASE_URL + "/api/add-wedding-details/",
  UpdateWeddingDeatils: BASE_URL + "/api/update-wedding-details/",
  GetWeddingDeatils: BASE_URL + "/api/get-wedding-details/",
  GetWeddingDetailsByid: BASE_URL + "/api/wedding-details-byid/",

  // New Wedding Banner

  AddNewWeddingBanner: BASE_URL + "/api/add-new-wedding-banner/",
  UpdateNewWeddingBanner: BASE_URL + "/api/update-new-wedding-banner/",
  GetNewWeddingBanner: BASE_URL + "/api/get-new-wedding-banner/",
  GetNewWeddingBannerByid: BASE_URL + "/api/new-wedding-banner-byid/",

  GetNewWeddingPageData: BASE_URL + "/api/get-new-wedding-data/",

  // New Wedding Inspiration

  AddNewWeddingInspiration: BASE_URL + "/api/add-new-wedding-inspiration/",
  UpdateNewWeddingInspiration: BASE_URL + "/api/update-new-wedding-inspiration/",
  GetNewWeddingInspiration: BASE_URL + "/api/get-new-wedding-inspiration/",
  GetNewWeddingInspirationByid: BASE_URL + "/api/get-new-wedding-inspiration-byid/",
  DeleteNewWeddingInspirationByid:
    BASE_URL + "/api/delete-new-wedding-inspiration-byid/",


  // New Real Wedding

  AddNewRealWedding: BASE_URL + "/api/add-new-real-wedding/",
  UpdateNewRealWedding: BASE_URL + "/api/update-new-real-wedding/",
  GetNewRealWedding: BASE_URL + "/api/get-new-real-wedding/",
  GetNewRealWeddingByid: BASE_URL + "/api/get-new-real-wedding-byid/",
  DeleteNewRealWeddingByid:
    BASE_URL + "/api/delete-new-real-wedding-byid/",

  // New Wedding Venu

  AddNewWeddingVenu: BASE_URL + "/api/add-new-wedding-venu/",
  UpdateNewWeddingVenu: BASE_URL + "/api/update-new-wedding-venu/",
  GetNewWeddingVenu: BASE_URL + "/api/get-new-wedding-venu/",
  GetNewWeddingVenuByid: BASE_URL + "/api/get-new-wedding-venu-byid/",
  DeleteNewWeddingVenuByid: BASE_URL + "/api/delete-new-wedding-venu-byid/",


  // New Wedding Testimonial

  AddNewWeddingTestimonial: BASE_URL + "/api/add-new-wedding-testimonial/",
  UpdateNewWeddingTestimonial: BASE_URL + "/api/update-new-wedding-testimonial/",
  GetNewWeddingTestimonial: BASE_URL + "/api/get-new-wedding-testimonial/",
  GetNewWeddingTestimonialByid: BASE_URL + "/api/get-new-wedding-testimonial-byid/",
  DeleteNewWeddingTestimonialByid: BASE_URL + "/api/delete-new-wedding-testimonial-byid/",


  // New Wedding Highlights

  AddNewWeddingHighlights: BASE_URL + "/api/add-new-wedding-highlights/",
  UpdateNewWeddingHighlights: BASE_URL + "/api/update-new-wedding-highlights/",
  GetNewWeddingHighlights: BASE_URL + "/api/get-new-wedding-highlights/",
  GetNewWeddingHighlightsByid: BASE_URL + "/api/get-new-wedding-highlights-byid/",
  DeleteNewWeddingHighlightsByid: BASE_URL + "/api/delete-new-wedding-highlights-byid/",


  //Wedding

  AddWedding: BASE_URL + "/api/add-wedding/",
  UpdateWedding: BASE_URL + "/api/update-wedding/",
  GetWedding: BASE_URL + "/api/get-wedding/",
  GetWeddingByid: BASE_URL + "/api/wedding-byid/",
  DeleteWedding: BASE_URL + "/api/wedding-delete/",
  //Wedding Banner

  AddWeddingBanner: BASE_URL + "/api/add-wedding-banner/",
  UpdateWeddingBanner: BASE_URL + "/api/update-wedding-banner/",
  GetWeddingBanner: BASE_URL + "/api/get-wedding-banner/",
  GetWeddingBannerByid: BASE_URL + "/api/wedding-banner-byid/",
  DeleteWeddingBannerByid: BASE_URL + "/api/delete-wedding-banner-byid/",

  //Wedding Real and Venu

  AddWeddingReal: BASE_URL + "/api/add-wedding-real/",
  UpdateWeddingReal: BASE_URL + "/api/update-wedding-real/",
  GetWeddingReal: BASE_URL + "/api/get-wedding-real/",
  GetWeddingRealByid: BASE_URL + "/api/get-wedding-real-byid/",
  DeleteWeddingRealByid: BASE_URL + "/api/delete-wedding-real-byid/",

  //Wedding Inspiration

  AddWeddingInspiration: BASE_URL + "/api/add-wedding-inspiration/",
  UpdateWeddingInspiration: BASE_URL + "/api/update-wedding-inspiration/",
  GetWeddingInspiration: BASE_URL + "/api/get-wedding-inspiration/",
  GetWeddingInspirationByid: BASE_URL + "/api/get-wedding-inspiration-byid/",
  DeleteWeddingInspirationByid:
    BASE_URL + "/api/delete-wedding-inspiration-byid/",

  // Event banner

  AddEventBanner: BASE_URL + "/api/add-event-banner/",
  UpdateEventBanner: BASE_URL + "/api/update-event-banner/",
  GetEventBanner: BASE_URL + "/api/get-event-banner/",
  GetEventBannerByid: BASE_URL + "/api/event-banner-byid/",
  DeleteEventBannerByid: BASE_URL + "/api/delete-event-banner-byid/",

  //Event

  AddEvent: BASE_URL + "/api/add-event/",
  UpdateEvent: BASE_URL + "/api/update-event/",
  GetEvent: BASE_URL + "/api/get-event/",
  GetEventByid: BASE_URL + "/api/get-event-byid/",
  DeleteEventByid: BASE_URL + "/api/delete-event-byid/",

  //Event Blog

  AddEventBlog: BASE_URL + "/api/add-event-blog/",
  UpdateEventBlog: BASE_URL + "/api/update-event-blog/",
  GetEventBlog: BASE_URL + "/api/get-event-blog/",
  GetEventBlogByid: BASE_URL + "/api/get-event-blog-byid/",
  DeleteEventBlogByid: BASE_URL + "/api/delete-event-blog-byid/",

  //mix api for all wedding data
  GetWeddingPageData: BASE_URL + "/api/get-wedding-data/",
  GetEventPageData: BASE_URL + "/api/get-event-data/",
  CreateWeddingEnquiryForm: BASE_URL + "/api/create-wedding-enquiry/",
  GetEnqiryData: BASE_URL + "/api/get-enquiry/",
  DeleteEnquiryByid: BASE_URL + "/api/delete-enquiry/",

  //Email sent

  SendEmail: BASE_URL + "/api/email-sent",

  // Dining city

  GetDinningByCity: BASE_URL + "/api/get-dinning-by-city",
  AddDining: BASE_URL + "/api/add-dining",
  UpdateDining: BASE_URL + "/api/update-dining/",
  GetDining: BASE_URL + "/api/get-dining",
  DeleteDining: BASE_URL + "/api/delete-dining/",
  GetDiningByid: BASE_URL + "/api/get-dining-byid/",

  // Dining city

  AddDiningCity: BASE_URL + "/api/add-dining-city",
  UpdateDiningCity: BASE_URL + "/api/update-dining-city/",
  GetDiningCity: BASE_URL + "/api/get-dining-city",
  DeleteDiningCity: BASE_URL + "/api/delete-dining-city/",
  GetDiningCityByid: BASE_URL + "/api/get-dining-city-byid/",

  //Item Type

  AddItemType: BASE_URL + "/api/add-item-type",
  UpdateItemType: BASE_URL + "/api/update-item-type/",
  GetItemType: BASE_URL + "/api/item-type-list",
  GetItemTypeById: BASE_URL + "/api/itemTypeListById/",
  GetItemTypeByRoomId: BASE_URL + "/api/itemTypeById/",
  DeleteItemType: BASE_URL + "/api/delete-item-type/",

  //dinning stocks

  PostDinningStock: BASE_URL + "/api/add-dinning-stock",
  UpdateDinningStockStatus: BASE_URL + "/api/add-dinning-stock-status/",
  GetDinningStock: BASE_URL + "/api/get-dinning-stock",
  GetDinningStockByid: BASE_URL + "/api/get-dinning-stock-byid",
  GetDinningStockByDinningid: BASE_URL + "/api/getdinningstockbydinningid/",

  //dinning booking

  DinningPostBooking: BASE_URL + "/api/dinning-booking/",

  //Activity Apis

  // Activity city

  GetActivityByCity: BASE_URL + "/api/get-activity-by-city",
  AddActivity: BASE_URL + "/api/add-activity",
  UpdateActivity: BASE_URL + "/api/update-activity/",
  GetActivity: BASE_URL + "/api/get-activity",
  DeleteActivity: BASE_URL + "/api/delete-activity/",
  GetActivityByid: BASE_URL + "/api/get-activity-byid/",

  // Activity city

  AddActivityCity: BASE_URL + "/api/add-activity-city",
  UpdateActivityCity: BASE_URL + "/api/update-activity-city/",
  GetActivityCity: BASE_URL + "/api/get-activity-city",
  DeleteActivityCity: BASE_URL + "/api/delete-activity-city/",
  GetActivityCityByid: BASE_URL + "/api/get-activity-city-byid/",

  //item type
  PostActivityItemType: BASE_URL + "/api/add-activity-item-type",
  UpdateActivityItemType: BASE_URL + "/api/update-activity-item-type/",
  GetActivityItemType: BASE_URL + "/api/item-type-list-by-activityid/",
  DeleteActivityItemType: BASE_URL + "/api/delete-activity-item-type/",
  GetItemActivityTypeByid: BASE_URL + "/api/activityitemTypeListById/",
  GetItemActivityFaqByid: BASE_URL + "/api/activityFaqById/",

  GetActivityFaq: BASE_URL + "/api/faq-by-activityid/",
  DeleteActivityFaq: BASE_URL + "/api/delete-activity-faq/",
  PostActivityFaq: BASE_URL + "/api/add-activity-faq",
  UpdateActivityFaq: BASE_URL + "/api/update-activity-faq/",

  //dinning stock

  PostActivityStock: BASE_URL + "/api/add-activity-stock/",
  UpdateActivityStockStatus: BASE_URL + "/api/add-activity-stock-status/",
  GetActivityStock: BASE_URL + "/api/get-activity-stock/",
  GetActivityStockByid: BASE_URL + "/api/get-activity-stock-byid/",
  AddActivityTicket: BASE_URL + "/api/add-activity-ticket/",
  GetActivityTicket: BASE_URL + "/api/get-activity-ticket/",



  //web
  GetActivityStockByActivityid: BASE_URL + "/api/getactivitystockbyactivityid/",
  //Activity booking

  PostActivityBooking: BASE_URL + "/api/activity-booking",

  GetPackage: BASE_URL + "/api/get-package",
  PostPackage: BASE_URL + "/api/add-package",
  UpdatePackage: BASE_URL + "/api/update-package/",
  GetPackageByid: BASE_URL + "/api/get-package-byid/",
  DeletePackage: BASE_URL + "/api/delete-package/",
  GetPackageByCity: BASE_URL + "/api/get-package-by-city",

  // Package City

  GetPackageCity: BASE_URL + "/api/get-package-city",
  PostPackageCity: BASE_URL + "/api/add-package-city",
  UpdatePackageCity: BASE_URL + "/api/update-package-city/",
  GetPackageCityByid: BASE_URL + "/api/get-package-city-byid/",
  DeletePackageCity: BASE_URL + "/api/delete-package-city/",

  //item type
  PostPackageItemType: BASE_URL + "/api/add-package-item-type",
  UpdatePackageItemType: BASE_URL + "/api/update-package-item-type/",
  GetPackageItemType: BASE_URL + "/api/item-type-list-by-packageid/",
  DeletePackageItemType: BASE_URL + "/api/delete-package-item-type/",
  GetPackageItemTypeByid: BASE_URL + "/api/packageitemTypeListById/",

  //Package stock

  PostPackageStock: BASE_URL + "/api/add-package-stock",
  UpdatePackageStockStatus: BASE_URL + "/api/add-package-stock-status/",
  GetPackageStock: BASE_URL + "/api/get-package-stock",
  GetPackageStockByid: BASE_URL + "/api/get-package-stock-byid",

  //web
  GetPackageStockByPackageid: BASE_URL + "/api/getpackagestockbypackageid/",

  //Package booking

  PostPackagesBooking: BASE_URL + "/api/package-booking",

  // Vehicles

  GetVehicles: BASE_URL + "/api/get-vehicles",
  PostVehicles: BASE_URL + "/api/add-vehicles",
  UpdateVehicles: BASE_URL + "/api/update-vehicles/",
  GetVehiclesByid: BASE_URL + "/api/get-vehicles-byid/",
  DeleteVehicles: BASE_URL + "/api/delete-vehicles/",
  GetVehiclesByCity: BASE_URL + "/api/get-vehicles-by-city",

  // Vehicles City

  GetVehiclesCity: BASE_URL + "/api/get-vehicles-city",
  PostVehiclesCity: BASE_URL + "/api/add-vehicles-city",
  UpdateVehiclesCity: BASE_URL + "/api/update-vehicles-city/",
  GetVehiclesCityByid: BASE_URL + "/api/get-vehicles-city-byid/",
  DeleteVehiclesCity: BASE_URL + "/api/delete-vehicles-city/",

  //item type
  PostVehiclesItemType: BASE_URL + "/api/add-vehicles-item-type",
  UpdateVehiclesItemType: BASE_URL + "/api/update-vehicles-item-type/",
  GetVehiclesItemType: BASE_URL + "/api/item-type-list-by-vehiclesid/",
  DeleteVehiclesItemType: BASE_URL + "/api/delete-vehicles-item-type/",
  GetVehiclesItemTypeByid: BASE_URL + "/api/vehiclesitemTypeListById/",

  //Vehicles stock

  PostVehiclesStock: BASE_URL + "/api/add-vehicles-stock",
  UpdateVehiclesStockStatus: BASE_URL + "/api/add-vehicles-stock-status/",
  GetVehiclesStock: BASE_URL + "/api/get-vehicles-stock",
  GetVehiclesStockByid: BASE_URL + "/api/get-vehicles-stock-byid",

  //web
  GetVehiclesStockByVehiclesid: BASE_URL + "/api/getvehiclesstockbyvehiclesid/",

  //Vehicles booking

  PostVehiclesBooking: BASE_URL + "/api/vehicles-booking",

  //pages

  GetData: BASE_URL + "/api/page-list",
  GetDetailByTypeData: BASE_URL + "/api/page-detail/",
  GetDetailByIdData: BASE_URL + "/api/page-detailbyid/",
  Update: BASE_URL + "/api/page-update/",
  Upload: BASE_URL + "/api/page-upload",
  DeletePage: BASE_URL + "/api/delete-page/",

  //blogs

  GetDataBlog: BASE_URL + "/api/blog",
  GetDetailDataBlog: BASE_URL + "/api/blog-detail/",
  GetCategoryDataBlog: BASE_URL + "/api/blog-category",
  GetCategoryById: BASE_URL + "/api/blog-category-detail/",
  UpdateBlog: BASE_URL + "/api/blog-update/",
  UploadCategoryBlog: BASE_URL + "/api/add-blog-category",
  UpdateCategoryBlog: BASE_URL + "/api/update-blog-category/",
  UploadBlog: BASE_URL + "/api/blog-upload",
  DeleteCatBlog: BASE_URL + "/api/delete-blog-category/",
  DeleteSubCatBlog: BASE_URL + "/api/delete-blog/",

  GetSubCategory: BASE_URL + "/api/blog-sub-category",
  GetSubCategoryById: BASE_URL + "/api/blog-sub-category/",
  UploadSubCategory: BASE_URL + "/api/add-blog-sub-category/",
  UpdateSubCategory: BASE_URL + "/api/update-blog-sub-category/",
  DeleteSubCat: BASE_URL + "/api/delete-blog-sub-category/",
  GetSubCategoryByCatId: BASE_URL + "/api/blog-sub-categor-by-cat-id/",
  //web
  GetWebBlogCategoryData: BASE_URL + "/api/web-blog-category",
  GetWebBlogData: BASE_URL + "/api/web-blog/",
  GetWebBlogBySubCatNameData: BASE_URL + "/api/web-blog-by-subcat-name/",

  // blog banner

  AddBlogBanner: BASE_URL + "/api/add-blog-banner/",
  UpdateBlogBanner: BASE_URL + "/api/update-blog-banner/",
  GetBlogBanner: BASE_URL + "/api/get-blog-banner/",
  GetBlogBannerByid: BASE_URL + "/api/blog-banner-byid/",

  //mice

  GetMiceDetail: BASE_URL + "/api/get-mice-details",
  AddMiceDetail: BASE_URL + "/api/add-mice-details",
  UpdateMiceDetail: BASE_URL + "/api/update-mice-details/",
  GetMiceDeatilsByid: BASE_URL + "/api/mice-details-byid/",

  // Mice

  GetMice: BASE_URL + "/api/get-mice",
  AddMice: BASE_URL + "/api/add-mice",
  UpdateMice: BASE_URL + "/api/update-mice/",
  GetMiceByid: BASE_URL + "/api/mice-byid/",
  DeleteMice: BASE_URL + "/api/mice-delete/",

  // Mice Banner

  GetMiceBanner: BASE_URL + "/api/get-mice-banner",
  AddMiceBanner: BASE_URL + "/api/add-mice-banner",
  UpdateMiceBanner: BASE_URL + "/api/update-mice-banner/",
  GetMiceBannerByid: BASE_URL + "/api/mice-banner-byid/",
  DeleteMiceBannerByid: BASE_URL + "/api/delete-mice-banner-byid/",

  // Mice Real

  GetMiceReal: BASE_URL + "/api/get-mice-venue",
  AddMiceReal: BASE_URL + "/api/add-mice-venue",
  UpdateMiceReal: BASE_URL + "/api/update-mice-venue/",
  GetMiceRealByid: BASE_URL + "/api/get-mice-venue-byid/",
  DeleteMiceRealByid: BASE_URL + "/api/delete-mice-venue-byid/",

  // Mice Inspiration

  GetMiceConcept: BASE_URL + "/api/get-mice-concept",
  AddMiceConcept: BASE_URL + "/api/add-mice-concept",
  UpdateMiceConcept: BASE_URL + "/api/update-mice-concept/",
  GetMiceConceptByid: BASE_URL + "/api/get-mice-concept-byid/",
  DeleteMiceConceptByid: BASE_URL + "/api/delete-mice-concept-byid/",

  GetMicePageData: BASE_URL + "/api/get-mice-data/",

  //testimonial
  GetDataTestimonial: BASE_URL + "/api/testimonial",
  GetDetailByPageDataTestimonial: BASE_URL + "/api/testimonial-by-page/",
  GetDetailDataTestimonial: BASE_URL + "/api/testimonial-detail/",

  UpdateTestimonial: BASE_URL + "/api/testimonial-update/",
  UploadTestimonial: BASE_URL + "/api/add-testimonial",

  DeleteTestimonial: BASE_URL + "/api/delete-testimonial/",

  //subscribe

  GetDataSubscribe: BASE_URL + "/api/subscribe",
  UploadSubscribe: BASE_URL + "/api/add-subscribe/",
  DeleteSubscribe: BASE_URL + "/api/subscribe-delete/",

  //social link
  GetDataSocialLink: BASE_URL + "/api/socilal-links",
  GetDetailByIdSocialLink: BASE_URL + "/api/socilal-links/",
  UploadSocialLink: BASE_URL + "/api/add-socilal-links/",
  UpdateSocialLink: BASE_URL + "/api/update-socilal-links/",
  DeleteSocialLink: BASE_URL + "/api/socilal-links-delete/",

  //faq, help and support
  GetDataCustomerHelp: BASE_URL + "/api/customer-help",
  GetDetailByIdCustomerHelp: BASE_URL + "/api/customer-help/",
  UploadCustomerHelp: BASE_URL + "/api/add-customer-help/",
  UpdateCustomerHelp: BASE_URL + "/api/update-customer-help/",
  DeleteCustomerHelp: BASE_URL + "/api/customer-help-delete/",

  GetDataFAQ: BASE_URL + "/api/faq",
  GetDetailByIdFAQ: BASE_URL + "/api/faq/",
  UploadFAQ: BASE_URL + "/api/add-faq/",
  UpdateFAQ: BASE_URL + "/api/update-faq/",
  DeleteFAQ: BASE_URL + "/api/faq-delete/",

  //partnerwithus

  GetDataWhyChooseUs: BASE_URL + "/api/why-choose-us",
  GetDetailByIdWhyChooseUs: BASE_URL + "/api/why-choose-us-detail/",
  UpdateWhyChooseUs: BASE_URL + "/api/why-choose-us-update/",
  UploadWhyChooseUs: BASE_URL + "/api/add-why-choose-us",
  DeleteWhyChooseUs: BASE_URL + "/api/delete-why-choose-us/",

  //productblog

  GetDataProductBlog: BASE_URL + "/api/product-blog-list",
  GetDetailByTypeDataProductBlog: BASE_URL + "/api/product-blog-detail/",
  GetDetailByIdDataProductBlog: BASE_URL + "/api/product-blog-detailbyid/",
  UpdateProductBlog: BASE_URL + "/api/product-blog-update/",
  UploadProductBlog: BASE_URL + "/api/product-blog-upload",
  DeletePageProductBlog: BASE_URL + "/api/delete-product-blog/",

  //homescreen

  GetDataHomeBanner: BASE_URL + "/api/home-banner-list",
  GetHomeBannerDetailById: BASE_URL + "/api/home-banner-detailbyid/",
  UpdateHomeBanner: BASE_URL + "/api/home-banner-update/",
  UploadHomeBanner: BASE_URL + "/api/home-banner-upload",
  DeleteHomeBanner: BASE_URL + "/api/delete-home-banner/",

  GetDataSectionSecond: BASE_URL + "/api/section-second-list",
  GetSectionSecondDetailById: BASE_URL + "/api/section-second-detailbyid/",
  UpdateSectionSecond: BASE_URL + "/api/section-second-update/",
  UploadSectionSecond: BASE_URL + "/api/section-second-upload",
  DeleteSectionSecond: BASE_URL + "/api/delete-section-second/",

  GetDataRecomendedBlog: BASE_URL + "/api/recomended-blog-list",
  GetRecomendedBlogDetailById: BASE_URL + "/api/recomended-blog-detailbyid/",
  UpdateRecomendedBlog: BASE_URL + "/api/recomended-blog-update/",
  UploadRecomendedBlog: BASE_URL + "/api/recomended-blog-upload",
  DeleteRecomendedBlog: BASE_URL + "/api/delete-recomended-blog/",

  GetDataSectionForth: BASE_URL + "/api/section-forth-list",
  GetSectionForthDetailById: BASE_URL + "/api/section-forth-detailbyid/",
  UpdateSectionForth: BASE_URL + "/api/section-forth-update/",
  UploadSectionForth: BASE_URL + "/api/section-forth-upload",
  DeleteSectionForth: BASE_URL + "/api/delete-section-forth/",

  GetDataJoinNetwork: BASE_URL + "/api/join-network-list",
  GetJoinNetworkDetailById: BASE_URL + "/api/join-network-detailbyid/",
  UpdateJoinNetwork: BASE_URL + "/api/join-network-update/",
  UploadJoinNetwork: BASE_URL + "/api/join-network-upload",
  DeleteJoinNetwork: BASE_URL + "/api/delete-join-network/",
  GetWebHomeAllData: BASE_URL + "/api/get-web-all-data/",

  //wishlist
  GetDatawishlist: BASE_URL + "/api/wishlist",
  GetwishlistData: BASE_URL + "/api/get-wishlist",
  GetwishlistbyUseridData: BASE_URL + "/api/get-wishlist-by-userid/",
  Uploadwishlist: BASE_URL + "/api/add-wishlist/",
  Deletewishlist: BASE_URL + "/api/wishlist-delete/",
};

export const METHODS = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const HEADERS = {
  Accept: "applicaiton/json",
  // 'Content-Type': 'multipart/form-data',
  "Content-Type": "application/json",
};

export const TabBarLabels = {
  Hotel: "hotel",
  Dinning: "dinning",
  Activities: "activities",
  Vehicles: "vehicles",
  Packages: "packages",
};
export const productpageNames = [
  "hotel",
  "dinning",
  "activities",
  "vehicles",
  "packages",
];
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const reg = /^[0-9]*$/;
export const rule = /^[a-zA-Z ]{2,40}$/;
export const pattern = /[1-9]/g;
export const dn = /[A-Za-z]$/;
export const DOB_REGEX =
  /^((0[0-9])|(1[012]))-((0[1-9])|([12][0-9])|(3[01]))-((20[012]\d|19\d\d)|(1\d|2[0123]))$/;
export function numberToEnglish(n) {
  var string = n.toString(),
    units,
    tens,
    scales,
    start,
    end,
    chunks,
    chunksLen,
    chunk,
    ints,
    i,
    word,
    words,
    and = "and";

  /* Remove spaces and commas */
  string = string.replace(/[, ]/g, "");

  /* Is number zero? */
  if (parseInt(string) === 0) {
    return "zero";
  }

  /* Array of units as words */
  units = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  /* Array of tens as words */
  tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  /* Array of scales as words */
  scales = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
    "sextillion",
    "septillion",
    "octillion",
    "nonillion",
    "decillion",
    "undecillion",
    "duodecillion",
    "tredecillion",
    "quatttuor-decillion",
    "quindecillion",
    "sexdecillion",
    "septen-decillion",
    "octodecillion",
    "novemdecillion",
    "vigintillion",
    "centillion",
  ];

  /* Split user argument into 3 digit chunks from right to left */
  start = string.length;
  chunks = [];
  while (start > 0) {
    end = start;
    chunks.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  /* Check if function has enough scale words to be able to stringify the user argument */
  chunksLen = chunks.length;
  if (chunksLen > scales.length) {
    return "";
  }

  /* Stringify each integer in each chunk */
  words = [];
  for (i = 0; i < chunksLen; i++) {
    chunk = parseInt(chunks[i]);

    if (chunk) {
      /* Split chunk into array of individual integers */
      ints = chunks[i].split("").reverse().map(parseFloat);

      /* If tens integer is 1, i.e. 10, then add 10 to units integer */
      if (ints[1] === 1) {
        ints[0] += 10;
      }

      /* Add scale word if chunk is not zero and array item exists */
      if ((word = scales[i])) {
        words.push(word);
      }

      /* Add unit word if array item exists */
      if ((word = units[ints[0]])) {
        words.push(word);
      }

      /* Add tens word if array item exists */
      if ((word = tens[ints[1]])) {
        words.push(word);
      }

      /* Add 'and' string after units or tens integer if: */
      if (ints[0] || ints[1]) {
        /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
        if (ints[2] || (!i && chunksLen)) {
          words.push(and);
        }
      }

      /* Add hundreds word if array item exists */
      if ((word = units[ints[2]])) {
        words.push(word + " hundred");
      }
    }
  }

  return words.reverse().join(" ");
}
export const NumberSeprator = (num) => {
  return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};
export const getDateNow = () => {
  const date = new Date().toLocaleDateString().split("/");
  const year = date[2];
  const month = date[1];
  const dateChange = date[0];

  const convertDate = year + "-" + month + "-" + dateChange;

  return convertDate;
};
export const hotelstarratingOptions = [
  { value: "3.0", label: "3.0" },
  { value: "3.5", label: "3.5" },
  { value: "4.0", label: "4.0" },
  { value: "4.5", label: "4.5" },
  { value: "5.0", label: "5.0" },
];
export const hotelTypeOptions = [
  { value: "Hotel", label: "Hotel" },
  { value: "Serenade", label: "Serenade" },
];
export const hotelpromo_type = [
  { value: "Normal", label: "Normal" },
  { value: "Best Seller", label: "Best Seller" },
  { value: "Promotion of month", label: "Promotion of month" },
];
export const hotelproductTypeOptions = [
  { value: `"Bhatrat's choice`, label: `"Bhatrat's choice` },
  { value: "SHA+ Standard", label: "SHA+ Standard" },
  { value: "Kid Friendly", label: "Kid Friendly" },
];
export const hotelplaceoffers = [
  { value: "Swimming pool", label: "Swimming pool" },
  { value: "Fitness center", label: "Fitness center" },
  { value: "Lotus spa", label: "Lotus spa" },
  { value: "Restaurant & Lounge", label: "Restaurant & Lounge" },
  { value: "Security parking", label: "Security parking" },
  { value: "Meetings & seminars", label: "Meetings & seminars" },
  { value: "Events & weddings", label: "Events & weddings" },
  { value: "Business Centre", label: "Business Centre" },
  { value: "CCTV", label: "CCTV" },
  { value: "Kid’s Pool", label: "Kid’s Pool" },
  { value: "Outdoor Pool", label: "Outdoor Pool" },
];

export const pricestockbenefitOptions = [
  { value: "Breakfast Included", label: "Breakfast Included" },
  { value: "Best rate Guarantee", label: "Best rate Guarantee" },
  { value: "Instant Confirmation", label: "Instant Confirmation" },
  { value: "Breakfast & Dinner", label: "Breakfast & Dinner" },

  { value: "ABF, Lunch,Dinner", label: "ABF, Lunch,Dinner" },
];
export const pricestockpaymentmethod = [
  { value: "Pay Now", label: "Pay Now" },
  { value: "No Payment", label: "No Payment" },
  { value: "Pay Later", label: "Pay Later" },
  { value: "Pay at Property", label: "Pay at Property" },
];
export const pricestockbookingtype = [
  { value: "Instant", label: "Instant" },
  { value: "Serenade", label: "Serenade" },
];
export const pricestockcampaignname = [
  { value: "Room Only", label: "Room Only" },
  { value: "Room with Breakfast", label: "Room with Breakfast" },
  { value: "Room with Half Board", label: "Room with Half Board" },
  { value: "Room with Full Board", label: "Room with Full Board" },
];

export const stockbenefitOptions = [
  { value: "Instant Confirmation", label: "Instant Confirmation" },
  { value: "Availability Today", label: "Availability Today" },
  { value: "Best rate Guarantee", label: "Best rate Guarantee" },
  { value: "No Cancellation", label: "No Cancellation" },
];

export const wedding_view_type = [
  { value: "Desktop", label: "Desktop" },
  { value: "Mobile", label: "Mobile" },
];

export const diningTypeOptions = [
  { value: "Ala Carte", label: "Ala Carte" },
  { value: "Buffet", label: "Buffet" },
];
export const ItemTypeOptions = [
  { value: "Meal", label: "Meal" },
  { value: "vehicles", label: "Vehicles" },
  { value: "Other", label: "Other" },
];
export const activityTypeOptions = [
  { value: "Activites", label: "Activites" },
  { value: "Golf", label: "Golf" },
  { value: "Wellness", label: "Wellness" },
  { value: "Staycation", label: "Staycation" },
];
export const activityItemTypeOptions = [
  { value: "Item", label: "Item" },
  { value: "Vehicle", label: "Vehicle" },
  { value: "Other", label: "Other" },
];
export const packageTypeOptions = [
  { value: "Golf", label: "Golf" },
  { value: "Wellness", label: "Wellness" },
  { value: "Staycation", label: "Staycation" },
];
export const mobileCode = [
  "+880",
  "+32",
  "+226",
  "+359",
  "+387",
  "+1-246",
  "+681",
  "+590",
  "+1-441",
  "+673",
  "+591",
  "+973",
  "+257",
  "+229",
  "+975",
  "+1-876",
  "+267",
  "+685",
  "+599",
  "+55",
  "+1-242",
  "+44-1534",
  "+375",
  "+501",
  "+7",
  "+250",
  "+381",
  "+670",
  "+262",
  "+993",
  "+992",
  "+40",
  "+690",
  "+245",
  "+1-671",
  "+502",
  "+30",
  "+240",
  "+590",
  "+81",
  "+592",
  "+44-1481",
  "+594",
  "+995",
  "+1-473",
  "+44",
  "+241",
  "+503",
  "+224",
  "+220",
  "+299",
  "+350",
  "+233",
  "+968",
  "+216",
  "+962",
  "+385",
  "+509",
  "+36",
  "+852",
  "+504",
  "+58",
  "+1-787 and 1-939",
  "+970",
  "+680",
  "+351",
  "+47",
  "+595",
  "+964",
  "+507",
  "+689",
  "+675",
  "+51",
  "+92",
  "+63",
  "+870",
  "+48",
  "+508",
  "+260",
  "+212",
  "+372",
  "+20",
  "+27",
  "+593",
  "+39",
  "+84",
  "+677",
  "+251",
  "+252",
  "+263",
  "+966",
  "+34",
  "+291",
  "+382",
  "+373",
  "+261",
  "+590",
  "+212",
  "+377",
  "+998",
  "+95",
  "+223",
  "+853",
  "+976",
  "+692",
  "+389",
  "+230",
  "+356",
  "+265",
  "+960",
  "+596",
  "+1-670",
  "+1-664",
  "+222",
  "+44-1624",
  "+256",
  "+255",
  "+60",
  "+52",
  "+972",
  "+33",
  "+246",
  "+290",
  "+358",
  "+679",
  "+500",
  "+691",
  "+298",
  "+505",
  "+31",
  "+47",
  "+264",
  "+678",
  "+687",
  "+227",
  "+672",
  "+234",
  "+64",
  "+977",
  "+674",
  "+683",
  "+682",
  "+225",
  "+41",
  "+57",
  "+86",
  "+237",
  "+56",
  "+61",
  "+1",
  "+242",
  "+236",
  "+243",
  "+420",
  "+357",
  "+61",
  "+506",
  "+599",
  "+238",
  "+53",
  "+268",
  "+963",
  "+599",
  "+996",
  "+254",
  "+211",
  "+597",
  "+686",
  "+855",
  "+1-869",
  "+269",
  "+239",
  "+421",
  "+82",
  "+386",
  "+850",
  "+965",
  "+221",
  "+378",
  "+232",
  "+248",
  "+7",
  "+1-345",
  "+65",
  "+46",
  "+249",
  "+1-809 and 1-829",
  "+1-767",
  "+253",
  "+45",
  "+1-284",
  "+49",
  "+967",
  "+213",
  "+1",
  "+598",
  "+262",
  "+1",
  "+961",
  "+1-758",
  "+856",
  "+688",
  "+886",
  "+1-868",
  "+90",
  "+94",
  "+423",
  "+371",
  "+676",
  "+370",
  "+352",
  "+231",
  "+266",
  "+66",
  "+228",
  "+235",
  "+1-649",
  "+218",
  "+379",
  "+1-784",
  "+971",
  "+376",
  "+1-268",
  "+93",
  "+1-264",
  "+1-340",
  "+354",
  "+98",
  "+374",
  "+355",
  "+244",
  "+1-684",
  "+54",
  "+61",
  "+43",
  "+297",
  "+91",
  "+358-18",
  "+994",
  "+353",
  "+62",
  "+380",
  "+974",
  "+258",
];
export const displaypageOptions = [
  { value: "Home", label: "Home" },
  { value: "Wedding", label: "Wedding" },
  { value: "Mice", label: "Mice" },
  { value: "Blog", label: "Blog" },
  { value: "Agent-Marketplace", label: "Agent-Marketplace" },
  { value: "Mice", label: "Mice" },
  { value: "Hotel", label: "Hotel" },
  { value: "Activities", label: "Activities" },
  { value: "Vehicles", label: "Vehicles" },
  { value: "Dinning", label: "Dinning" },
  { value: "Packages", label: "Packages" },
];
export const pagenameOptions = [
  { value: "privacy-policy", label: "Privacy Policy" },
  { value: "terms-and-condition", label: "Terms & Condition" },
  { value: "about-us", label: "About Us" },
  { value: "cookie-policy", label: "Cookie Policy" },
  { value: "refund-policy", label: "Refund Policy" },
];
