import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperNavButtons } from "../../components/SwiperNavButton";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { SwiperNavYellow } from "../../components/SwiperNavYellow";
import { fetchData } from "../../Helper/ApiCall";
import {
    END_POINT,
    BASE_URL_IMAGE,
    METHODS,
    NumberSeprator,
    numberToEnglish,
    TabBarLabels,
    BASE_URL,
} from "../../Helper/helper";

import "../../../src/weddingstyle.css";

import EditorRead from "../../components/EditorRead";
import Testimonial from "../../components/Testimonial";
import PartnersCarousel from "../../components/PartnersCarousel";
import Subscribe from "../../components/Subscribe";
import { useSelector, useDispatch } from "react-redux";
import { homedataUpdate } from "../../redux/reducers/homeReducer";
import ActivityLoader from "../../components/ActivityLoader";
import Testimonials from "./Testimonials";
import PartnersCarousel2 from "../../components/PartnersCrousal2";

export const NewWeddingScreen = () => {
    const dispatch = useDispatch();

    const { HomeBanner, SectionSecond, RecomendedBlog, SectionForth, loading } =
        useSelector((store) => store.homeReducer);

    const navigate = useNavigate();
    const [data, setdata] = useState({
        WeddingDetail: [],
        Wedding: [],
        WeddingBanner: [],
        WeddingReal: [],
        WeddingInspiration: [],
    });
    const [TestimonialData, setTestimonialData] = useState([]);
    const [BannerData, setBannerDetail] = useState([]);
    const [VenuData, setVenu] = useState([]);
    const [RealWeddingData, setRealWedding] = useState([]);
    const [InspirationData, setInspiration] = useState([]);
    const [HighlightsData, setHighlights] = useState([]);

    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            console.log('Video Element:', videoRef.current);
        }
    }, []);

    useEffect(() => {
        handlerData();
        if (SectionForth == "") {
            homeHandler();
        }
        BannerHandler();
        VenuHandler();
        RealWeddingHandler();
        InspirationHandler();
        HighlightsHandler();
        // handler();
        // handlerInspiration();
    }, []);
    const handlerData = async () => {
        try {
            const result = await fetchData(END_POINT.GetNewWeddingPageData);
            if (result.success == true) {
                setdata({
                    WeddingDetail: result.data.WeddingDetail,
                    // Wedding: result.data.Wedding,
                    // WeddingBanner: result.data.WeddingBanner,
                    // WeddingReal: result.data.WeddingReal,
                    // WeddingInspiration: result.data.WeddingInspiration,
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const homeHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetWebHomeAllData);
            console.log(result);
            if (result.success == true) {
                dispatch(homedataUpdate(result.data));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handler = async () => {
        try {
            const result = await fetchData(
                END_POINT.GetDetailByPageDataTestimonial + "Wedding"
            );
            if (result.success == true) {
                setTestimonialData(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const BannerHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetNewWeddingBannerByid + "66fe59dc12f1b2aa1cf0abac");

            if (result.success === true) {
                setBannerDetail(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const VenuHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetNewWeddingVenu);

            if (result.success == true) {
                setVenu(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const RealWeddingHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetNewRealWedding);

            if (result.success == true) {
                setRealWedding(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const InspirationHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetNewWeddingInspiration);

            if (result.success == true) {
                setInspiration(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const HighlightsHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetNewWeddingHighlights);

            if (result.success == true) {
                setHighlights(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add functionality to handle the email submission
        console.log(`Email submitted: ${email}`);
    };

    const [showMore, setShowMore] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);

    // To toggle between showing more or less text
    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    // Detect if the user is on a desktop view (adjust based on your breakpoints)
    const handleResize = () => {
        setIsDesktop(window.innerWidth > 768); // Assuming 768px is your mobile breakpoint
    };

    useEffect(() => {
        handleResize(); // Set initial view
        window.addEventListener('resize', handleResize); // Listen to window resize

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup event listener
        };
    }, []);

    // Limit words for mobile view
    const getLimitedText = (text, wordLimit) => {
        if (!text) return ''; // Return empty string if text is undefined or null
        const words = text.split(' ');
        return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
    };


    const stripHtmlTags = (html) => {
        if (!html) return '';
        return html.replace(/<[^>]+>/g, '');
    };


    const options = {
        loop: false,
        margin: 10,
        nav: true,
        dots: true,
        // navText: ['<', '>'],
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 4,
            },
        },
    };

    return (
        <>

            {/* Video Banner Section */}
            <div className="main_section banner">

                <video
                    ref={videoRef}
                    autoPlay
                    muted
                    loop
                    playsInline
                    preload="metadata"
                    style={{ width: '100%', height: 'auto' }} // Or use fixed dimensions if needed
                >
                    <source src={BASE_URL_IMAGE + BannerData.video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>


            </div>


            {/* About Section */}
            <div className="main_section about"
                style={{ backgroundImage: "url('/assets/images/our-concept.jpg')" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="about-text">
                                <h2> {BannerData.title} </h2>
                                <div className="article">
                                    {/* Full description for desktop, limited words for mobile */}
                                    <p>
                                        {isDesktop || showMore
                                            ? stripHtmlTags(BannerData.short_desc) // Display the full text without HTML tags
                                            : getLimitedText(BannerData.short_desc, 25) /* Show first 25 words on mobile */}
                                    </p>

                                    {/* Only show 'See more/See less' button on mobile view */}
                                    {!isDesktop && (
                                        <a className="moreless-button" onClick={toggleShowMore}>
                                            {showMore ? 'See less' : 'See more'}
                                        </a>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >


            <div className="main_section weeding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="about-text weeding-text">
                                <h2>Wedding inspiration!</h2>
                                <p>
                                    A series showcasing inspirational and stylish weddings held in
                                    the finest venues of Thailand.
                                </p>
                            </div>
                        </div>


                        {/* Desktop View */}
                        <div className="col-md-12 p-0 mt-4 desktop-view">
                            <div className="weeding-box">
                                <div className="left-weeding-box">
                                    {InspirationData[0] && (
                                        <div className="place-box-new">
                                            <div className="cus-place-new-img">
                                                <img
                                                    src={BASE_URL_IMAGE + InspirationData[0].image}
                                                    className="img-fluid"
                                                    alt={InspirationData[0].title || "Inspiration Image"}
                                                />
                                            </div>
                                            <div className="cus-place-text-new">
                                                <h3>{InspirationData[0].title}</h3>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="right-weeding-box">
                                    <div className="right-top">
                                        {InspirationData[1] && (
                                            <div className="place-box-new">
                                                <div className="cus-place-new-img">
                                                    <img
                                                        src={BASE_URL_IMAGE + InspirationData[1].image}
                                                        className="img-fluid"
                                                        alt={InspirationData[1].title || "Inspiration Image"}
                                                    />
                                                </div>
                                                <div className="cus-place-text-new">
                                                    <h3>{InspirationData[1].title}</h3>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="right-bottom">
                                        <div className="right-one">
                                            {InspirationData[2] && (
                                                <div className="place-box-new">
                                                    <div className="cus-place-new-img">
                                                        <img
                                                            src={BASE_URL_IMAGE + InspirationData[2].image}
                                                            className="img-fluid"
                                                            alt={InspirationData[2].title || "Inspiration Image"}
                                                        />
                                                    </div>
                                                    <div className="cus-place-text-new">
                                                        <h3>{InspirationData[2].title}</h3>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="right-two">
                                            {InspirationData[3] && (
                                                <div className="place-box-new">
                                                    <div className="cus-place-new-img">
                                                        <img
                                                            src={BASE_URL_IMAGE + InspirationData[3].image}
                                                            className="img-fluid"
                                                            alt={InspirationData[3].title || "Inspiration Image"}
                                                        />
                                                    </div>
                                                    <div className="cus-place-text-new">
                                                        <h3>{InspirationData[3].title}</h3>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Mobile View - Carousel */}
                        <div className="col-md-12 p-0 mt-4 mobile-view">
                            <OwlCarousel options={options}>
                                {InspirationData.map((inspiration, index) => (
                                    <div key={index} className="item">
                                        <img
                                            src={BASE_URL_IMAGE + inspiration.image}
                                            className="img-fluid"
                                            alt={inspiration.title || "Inspiration Image"}
                                        />
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>


                    </div>
                </div>
            </div>

            <div className="main_section real desktop-view">
                <div className="real-img">
                    <img src="/assets/images/icon2.png" className="img-fluid" alt="Icon 2" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="real-box">
                                <div className="real-text">
                                    <h2>
                                        The Real <br />
                                        Wedding
                                    </h2>
                                    <p>
                                        These Real couples said their "I do" in a glamorous way.
                                        Let's walk you through their fairytale wedding.
                                    </p>
                                </div>

                                <div className="wp-real-box">
                                    {RealWeddingData.map((wedding, index) => (
                                        <div key={index} className="main-real-box">
                                            <div className="place-box-new">
                                                <div className="cus-place-new-img">
                                                    <img
                                                        src={BASE_URL_IMAGE + wedding.image}
                                                        className="img-fluid"
                                                        alt={wedding.title}
                                                    />
                                                </div>
                                                <div className="cus-place-text-new">
                                                    <h3>
                                                        {wedding.link ? (
                                                            <a href={wedding.link} target="_blank" rel="noopener noreferrer">
                                                                {wedding.title}
                                                            </a>
                                                        ) : (
                                                            wedding.title
                                                        )}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>




                            </div>
                        </div>
                    </div>
                </div>

                <div className="real-img1">
                    <img src="/assets/images/icon1.png" className="img-fluid" alt="Icon 1" />
                </div>
            </div >


            <div className="main_section real mobile-view">
                <div className="real-img">
                    <img src="/assets/images/icon2.png" className="img-fluid" alt="Icon 2" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="real-box">
                                <div className="real-text">
                                    <h2>
                                        The Real <br />
                                        Wedding
                                    </h2>
                                    <p>
                                        These Real couples said their "I do" in a glamorous way.
                                        Let's walk you through their fairytale wedding.
                                    </p>
                                </div>



                                <div className="wp-real-box mobile-view">
                                    {RealWeddingData.length > 0 && (
                                        <OwlCarousel options={options}>
                                            {RealWeddingData.map((wedding, index) => (
                                                wedding.image ? (
                                                    <div key={index} className="item">
                                                        <img
                                                            src={BASE_URL_IMAGE + wedding.image}
                                                            className="img-fluid"
                                                            alt={wedding.title || "Wedding Image"}
                                                        />
                                                    </div>
                                                ) : null // Ignore entries without an image
                                            ))}
                                        </OwlCarousel>
                                    )}


                                </div>


                            </div>
                        </div>
                    </div>
                </div>

                <div className="real-img1">
                    <img src="/assets/images/icon1.png" className="img-fluid" alt="Icon 1" />
                </div>
            </div >


            {/* Wedding Venues Section */}
            < div className="main_section weeding venues" >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="about-text weeding-text">
                                <h2>Wedding Venues</h2>
                                <p>
                                    These venues are brimming with elegance, luxury, and natural beauty, <br />
                                    making them a perfect choice for your dream destination wedding
                                </p>
                            </div>
                        </div>

                        <div className="col-md-12 p-0 wp-according mt-4">
                            <div className="accordion">
                                {/* <div className="item bg-barcelona"
                                // style={{ backgroundImage: "url('/assets/images/Rectangle-1.jpg')" }}
                                >
                                    <div className="overlay">
                                        <div className="overlay-inner">
                                            <h3>
                                                <a href="#">Name of the hotel</a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="item bg-sydney"
                                // style={{ backgroundImage: "url('/assets/images/Rectangle-2.jpg')" }}
                                >
                                    <div className="overlay">
                                        <div className="overlay-inner">
                                            <h3>
                                                <a href="#">Name of the hotel</a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="item bg-venice"
                                // style={{ backgroundImage: "url('/assets/images/Rectangle-3.jpg')" }}
                                >
                                    <div className="overlay">
                                        <div className="overlay-inner">
                                            <h3>
                                                <a href="#">Name of the hotel</a>
                                            </h3>
                                        </div>
                                    </div>
                                </div> */}

                                {VenuData.map((venue, index) => (
                                    <div
                                        key={index}
                                        className="item"
                                        style={{
                                            backgroundImage: `url(${BASE_URL_IMAGE + venue.image})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                        }}
                                    >
                                        <div className="overlay">
                                            <div className="overlay-inner">
                                                <h3>
                                                    {venue.link ? (
                                                        <a href={venue.link} target="_blank" rel="noopener noreferrer">
                                                            {venue.title}
                                                        </a>
                                                    ) : (
                                                        venue.title
                                                    )}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                ))}


                            </div>
                        </div>


                        {/* <div className="col-md-12 p-0 wp-according mt-4 mobile-view">
                            <OwlCarousel options={options}>

                                <div className="item">
                                    <img src="/assets/images/Rectangle-1.jpg" className="img-fluid" alt="Must-do Activities" />
                                </div>
                                <div className="item">
                                    <img src="/assets/images/Rectangle-2.jpg" className="img-fluid" alt="Preferred Hotels" />
                                </div>
                                <div className="item">
                                    <img src="/assets/images/Rectangle-3.jpg" className="img-fluid" alt="Best Fine Dining 2" />
                                </div>
                            </OwlCarousel>
                        </div> */}
                    </div>
                </div>
            </div >

            <Testimonials />


            {/* Call to Action Section */}
            <div className="main_section cta"
                style={{ backgroundImage: "url('/assets/images/CTA.png')" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="cta-text">
                                <h2>
                                    Let our wedding planners help you bring <br />
                                    the wedding of your dreams to life
                                </h2>
                                <a href="#" className="cta-btn">Our service offerings</a>
                                <a href="#" className="cta-btn1">Online appointment request</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Highlight Section */}
            {/* <div className="main_section highlight desktop-view">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about-text">
                                <h2>Wedding Highlights!</h2>
                                <p>A series showcasing inspirational and stylish weddings held in the finest venues of Thailand.</p>
                            </div>
                            <div className="main-highlight-box">
                                <div className="highlight-box">
                                    <img src="/assets/images/Preferred-Hotels.jpg" className="img-fluid" alt="Preferred Hotels" />
                                </div>
                                <div className="highlight-box">
                                    <img src="/assets/images/Must-do-activities.jpg" className="img-fluid" alt="Must do activities" />
                                </div>
                                <div className="highlight-box">
                                    <img src="/assets/images/Preferred-Hotels.jpg" className="img-fluid" alt="Preferred Hotels" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="main_section highlight">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about-text">
                                <h2>Wedding Highlights!</h2>
                                <p>A series showcasing inspirational and stylish weddings held in the finest venues of Thailand.</p>
                            </div>
                            <br ></br>
                            <OwlCarousel options={options}>
                                {HighlightsData.map((highlight, index) => (
                                    <div className="item" key={index}>
                                        <video autoPlay muted loop playsInline preload="metadata">
                                            <source src={BASE_URL_IMAGE + highlight.video} type="video/mp4" />
                                        </video>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>


            <div className="main_section cta newsletter"
                style={{ backgroundImage: "url('/assets/images/CTA.png')" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="cta-text">
                                <h2>Sign up for our amazing deals & Newsletter</h2>
                                <div className="cus-newsletter">
                                    <form className="row g-3" onSubmit={handleSubmit}>
                                        <div className="col-md-8 col-7">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="inputEmail4"
                                                placeholder="Enter email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-4 col-5">
                                            <button type="submit" className="btn btn-primary">Subscribe</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PartnersCarousel2 />


        </>
    );
};
